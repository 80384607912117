import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./header.styles"

const Header = ({ links, className, siteTitle }) => {
  const [nav, setNav] = useState(false)
  return (
    <S.Wrapper className={className} data-cy="cy-header">
      <S.Container>
        <S.LinkTag to="/">
          <S.LogoText>{siteTitle}</S.LogoText>
        </S.LinkTag>
        <S.NavWrapper aria-label="site-navigation" data-cy="cy-nav">
          <S.Icon onClick={() => setNav(!nav)} />
          <S.LinksWrapper className={!nav && "hide"}>
            {links.map((link, i) => {
              return (
                <S.Item key={i}>
                  <S.LinkTag to={link.url}>{link.name}</S.LinkTag>
                </S.Item>
              )
            })}
          </S.LinksWrapper>
        </S.NavWrapper>
      </S.Container>
    </S.Wrapper>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  siteTitle: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
}

Header.defaultProps = {
  className: "",
}

export default styled(Header)``
