import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./blockOfText.styles"

const BlockOfText = ({ text, className, width }) => {
  return (
    <S.Wrapper className={className}>
      <S.Text width={width}>{text}</S.Text>
    </S.Wrapper>
  )
}

BlockOfText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
}

BlockOfText.defaultProps = {
  className: "",
  width: "",
}

export default styled(BlockOfText)``
