import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Button } from ".."
import * as S from "./ctaCard.styles"

const CTACard = ({ heading, title, desc, className, buttonText, link }) => {
  return (
    <S.Container className={className}>
      <S.Wrapper data-cy="joinUsLink">
        {heading && <S.SubTitle>{heading}</S.SubTitle>}
        <S.Title>{title}</S.Title>
        <S.Desc>{desc}</S.Desc>
        <Button label={buttonText} link={link} />
      </S.Wrapper>
    </S.Container>
  )
}

CTACard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  heading: PropTypes.string,
}

CTACard.defaultProps = {
  className: "",
  heading: "",
}

export default styled(CTACard)``
