import styled from "styled-components"
import media from "styled-media-query"
import { Arrow } from "../../utils/icons"

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 1.5rem;
  grid-column-gap: 0rem;
  grid-template-rows: autto 1fr 1.5fr;
  grid-template-columns: 1fr;
  background: ${(props) => props.theme.ui03};
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-template-columns: 1fr 3fr;
    grid-template-rows: min-content 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0rem;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      height: 100%;
      border-right: ${(props) => props.theme.borderStyle};
      top: 0;
      left: 25.7%;
    }
  `}
`
export const VideoWrap = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  .react-player {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
  }
  ${media.greaterThan("medium")`
  /* screen width is less than 768px (medium) */
    order: 1;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
`}
`
export const SlideTitle = styled.h1`
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin: 0px;
  font-family: ${(props) => props.theme.fontStyleTertiary};
  font-style: normal;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    margin-bottom: 1.5rem;
  `}
`
export const SlideWrap = styled.div`
  display: grid;
  grid-template-rows: auto max-content;
  ${media.greaterThan("medium")`
  /* screen width is less than 768px (medium) */
    grid-column: span 1;
    grid-row: 2 / 3;
    margin-right: 2rem;
`}
`
export const VideoTitleWrapper = styled.div``

export const VideoTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  color: ${(props) =>
    props.selected ? props.theme.text01 : props.theme.disabled01};
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    line-height: 1.5rem;
  `}
`
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10rem;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    margin-right: 2rem;
  `}
`
export const LeftArrow = styled(Arrow)`
  align-item: flex-start;
  width: 10px;
  height: 10px;
  cursor: pointer;
`
export const VideoNumber = styled.button`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) =>
    props.selected ? props.theme.text01 : props.theme.disabled01};
`
export const RightArrow = styled(Arrow)`
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
  cursor: pointer;
`
