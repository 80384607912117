import ContentTags from "./contentTags"
import ArticleCard from "./articleCard"
import SpeakerCard from "./speakerCard"
import Button from "./button"
import Details from "./details"
import TemplateHeading from "./templateHeading"
import Banner from "./banner"
import MethodContent from "./methodContent"
import HeadingCard from "./headingCard"
import EventCard from "./eventCard"
import MethodCard from "./methodCard"
import VideoSlide from "./videoSlide"
import Header from "./header"
import Socials from "./socials"
import CalendarAdd from "./calendarAdd"
import ArticleImages from "./articleImages"
import Footer from "./footer"
import BlockOfText from "./blockOfText"
import CTACard from "./ctaCard"
import NavigationCard from "./navigationCard"
import Layout from "./layout"
import FormatRichText from "./formatRichText"
import EventCardWLink from "./eventCardWLink"
import Download from "./download"
import TextButton from "./textButton"

export { default as SEO } from "./seo"

export {
  Header,
  ContentTags,
  ArticleCard,
  SpeakerCard,
  Button,
  Details,
  TemplateHeading,
  Banner,
  MethodContent,
  HeadingCard,
  EventCard,
  MethodCard,
  VideoSlide,
  Socials,
  CalendarAdd,
  ArticleImages,
  Footer,
  BlockOfText,
  CTACard,
  NavigationCard,
  Layout,
  FormatRichText,
  EventCardWLink,
  Download,
  TextButton,
}

export default {
  Header,
  ContentTags,
  ArticleCard,
  SpeakerCard,
  Button,
  Details,
  TemplateHeading,
  Banner,
  MethodContent,
  HeadingCard,
  EventCard,
  MethodCard,
  VideoSlide,
  Socials,
  CalendarAdd,
  ArticleImages,
  Footer,
  BlockOfText,
  CTACard,
  NavigationCard,
  Layout,
  FormatRichText,
  EventCardWLink,
  Download,
  TextButton,
}
