import styled from "styled-components"
// import media from "styled-media-query"

export const Wrapper = styled.div`
  display: grid;
  width: max-content;
  button {
    padding: 0rem;
    font-size: 0.875rem;
    color: ${(props) => props.theme.text03};
    &:hover {
      color: ${(props) => props.theme.hoverTertiary};
      background: none;
    }
    svg {
      > * {
        fill: currentColor;
      }
    }
  }
  .chq-atc--dropdown {
    margin-top: 1.5rem;
    box-shadow: none;
    width: 181px;
    border-radius: 0rem;
    color: ${(props) => props.theme.text01};
    border-top: 0px;
    border: ${(props) => props.theme.borderStyle};
    a {
      padding: 1rem;
      color: inherit;
      &:hover {
        background: ${(props) => props.theme.hoverPrimary};
      }
      &:first-child {
        border-top: none;
      }
    }
  }
`
