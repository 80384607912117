import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./button.styles"

const Button = ({ link, label, className }) => {
  const externalLink = link?.includes(`https` || `www`)
  return (
    <S.ButtonItem className={className}>
      <S.Link href={link}>
        {label}
        {externalLink && <S.Icon />}
      </S.Link>
    </S.ButtonItem>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

Button.defaultProps = {
  className: "",
}

export default styled(Button)``
