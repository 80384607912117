import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"
import { BurgerMenu } from "../../utils/icons"

export const LogoText = styled.h1`
  font-family: IBM Plex Sans;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
`

export const Icon = styled(BurgerMenu)``

export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  ${media.greaterThan("medium")`
      grid-template-rows: 1fr;
      align-items: center;
  `}
`

export const Wrapper = styled.header`
  width: 100%;
  background: ${(props) => props.theme.ui03};
`
// export const Logo = styled(Link)`
//   // background: pink;
//   // width: 138px;
//   height: 20px;
//   grid-column: 1 / span 1;
//   justify-self: start;
// `
export const LinksWrapper = styled.ul`
  grid-row: 2 / span 1;
  display: grid;
  grid-template-rows: 48px;
  grid-auto-rows: 48px;
  z-index: 10;
  background-color: ${(props) => props.theme.ui03};
  white-space: nowrap;
`

export const NavWrapper = styled.nav`
  ${Icon} {
    height: 20px;
    grid-row: 1 / span 1;
    justify-self: end;
  }
  height: inherit;
  grid-column: 2 / span 1;
  display: grid;
  grid-template-rows: 100% auto;
  align-items: center;
  .hide {
    display: none;
  }
  ${media.greaterThan("medium")`
  grid-column: 2 / span 1;
  grid-template-rows: auto;
  justify-content: end;
  ${Icon} {
    display: none;
  }
  ${LinksWrapper} {
    grid-row: 1;
    width: inherit;
    display: flex;
  }
`}
`
export const Item = styled.li`
  font-family: ${(props) => props.theme.ui03};
  font-size: 14px;
  line-height: 18px;
  padding: 1rem 0rem 1rem 1rem;
  color: ${(props) => props.theme.text01};
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    padding: 0rem;
    &:not(:last-of-type) {
      padding-right: 2rem;
    }
  `}
`

export const LinkTag = styled(Link)`
  display: inline-block;
  text-decoration: none;
  width: inherit;
  color: inherit;
  &:hover {
    color: ${(props) => props.theme.text03};
  }
`
