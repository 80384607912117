import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import AddToCalendar from "@culturehq/add-to-calendar"
// import { Calendar } from "../../utils/icons"
import * as S from "./calendarAdd.styles"

const CalendarAdd = ({
  className,
  title,
  description,
  location,
  startDatetime,
  endDatetime,
}) => {
  return (
    <S.Wrapper className={className}>
      {typeof window !== "undefined" && (
        <AddToCalendar
          event={{
            name: title,
            details: description,
            location,
            startsAt: startDatetime,
            endsAt: endDatetime,
          }}
        />
      )}
    </S.Wrapper>
  )
}

CalendarAdd.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  startDatetime: PropTypes.string.isRequired,
  endDatetime: PropTypes.string.isRequired,
}

CalendarAdd.defaultProps = {
  className: "",
}

export default styled(CalendarAdd)``
