import styled from "styled-components"
import media from "styled-media-query"
import { ArticleImages } from ".."

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  height: min-content;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.text01};
  font-family: ${(props) => props.theme.fontStylePrimary};
  p {
    &:empty {
      display: none;
    }
  }
`
export const H1 = styled.h1`
  font-size: 2rem;
  line-height: 2.375rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 2rem;
`

export const H2 = styled.h2`
  font-size: 1.5rem;
  line-height: 3rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 1rem;
`

export const H3 = styled.h3`
  font-size: 1.3rem;
  line-height: 3rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 1rem;
`
export const H4 = styled.h4`
  font-size: 1.2rem;
  line-height: 3rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 1rem;
`

export const H5 = styled.h5`
  font-size: 1.1rem;
  line-height: 3rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 1rem;
`

export const H6 = styled.h6`
  font-size: 1.1rem;
  line-height: 3rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 1rem;
`

export const P = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: ${(props) => props.theme.fontStyleSecondary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 1rem;
  white-space: pre-wrap;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
      margin-bottom: 2rem;
  `}
  b {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
`

export const OL = styled.ol`
  font-size: 0.875rem;
  line-height: 1.875rem;
  font-family: ${(props) => props.theme.fontStyleSecondary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 1rem;
  p {
    margin-bottom: 1rem;
  }
`

export const UL = styled.ul`
  font-size: 0.875rem;
  line-height: 1.875rem;
  font-family: ${(props) => props.theme.fontStyleSecondary};
  color: ${(props) => props.theme.text01};
  margin-bottom: 1rem;
  p {
    margin-bottom: 1rem;
  }
  li {
    list-style: disc;
    margin-left: 15px;
  }
`
export const Highlight = styled.span`
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: ${(props) => props.theme.fontStyleSecondary};
  color: ${(props) => props.theme.text01};
  position: relative;
  display: inline-block;
  &:before {
    content: "";
    background-color: #ea9c8560;
    height: 60%;
    width: 100%;
    position: absolute;
    bottom: 20%;
  }
`
export const Images = styled(ArticleImages)`
  margin-bottom: 1rem;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
      margin-bottom: 4rem;
      grid-template-columns: 62.5% 1fr;
  `}
`
