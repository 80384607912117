import styled, { css } from "styled-components"
import media from "styled-media-query"

// medium is 768px
// 1055px is the minmium container width on designs

export const Title = styled.h2`
  font-size: 2.5rem;
  line-height: 1.8125rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
`

export const Tag = styled.h1`
  margin: 0rem;
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 1rem;
  ${(props) =>
    props.lowerTheDate &&
    css`
      grid-row: end;
      align-self: center;
      margin: 0rem;
    `}
`
export const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  ${media.greaterThan("1056px")`
    padding-top: 100%;
  `}
`

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: min-content min-content min-content 1fr;
  grid-auto-rows: auto;
  color: inherit;
  text-decoration: none;
  height: inherit;
  ${media.greaterThan("1055px")`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
  `}
`

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  padding-top: 1.5rem;
  ${media.greaterThan("medium")`
      padding: 2.5rem 3rem 0rem 0rem;
  `}
`
