export default {
  ...{
    uiBackground: "#F2F5F6",
    ui01: "#E2EAEC",
    ui02: "#225956",
    ui03: "#fff",
    ui04: "#262626",
    ui05: "#2F2F2F",
    ui06: "#ED544E",
    ui07: "#EA9C85",
    ui08: "#FDF0D9",
    text01: "#000000",
    text02: "#525252",
    text03: "#ED544E",
    text04: "#fff",
    text05: "#EEFAFC",
    text06: "#393939",
    icon01: "#000000",
    hoverPrimary: "#E2EAEC",
    hoverSecondary: "#456B69",
    hoverTertiary: "#ED544E",
    borderStyle: "1px solid #000000",
    disabled01: "#767476",
    fontStylePrimary: "Argesta Display Regular",
    fontStyleSecondary: "IBM Plex Sans",
    fontStyleTertiary: "IBM Plex Mono",
    fontStyleQuaternary: "IBM Plex Mono Medium",
    buttonHover: "#ED544E",
    buttonBackground: "#EA9C85",
    buttonColor: "#393939",
    buttonSecondaryHover: "#E2EAEC",
    buttonSecondaryBackground: "#F2F5F6",
    buttonSecondaryColor: "#393939",
    baseWidth: "1056",
  },
}
