import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { format } from "date-fns"
import * as S from "./eventCardWLink.styles"

const EventCardWLink = ({
  link,
  title,
  desc,
  date,
  location,
  className,
  lowerTheTag,
  heading,
}) => {
  const formatedDate = date && format(new Date(date), "dd.MM.yyyy")
  const datePresented = location
    ? `${location} | ${formatedDate}`
    : formatedDate
  return (
    <S.Container className={className}>
      <S.Wrapper href={link} data-cy="cy-eventLinks">
        {heading ||
          (datePresented && (
            <S.Tag
              lowerTheDate={lowerTheTag}
              aria-label={
                (heading && `Heading`) || location
                  ? `Date and Event Location`
                  : `Date`
              }
            >
              {heading || datePresented}
            </S.Tag>
          ))}
        <S.Title>{title}</S.Title>
        {desc && (
          <S.Description>{`${desc.substring(0, 300)}...`}</S.Description>
        )}
        <S.InternalIcon />
      </S.Wrapper>
    </S.Container>
  )
}

EventCardWLink.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  date: PropTypes.string,
  location: PropTypes.string,
  lowerTheTag: PropTypes.bool,
  heading: PropTypes.string,
}

EventCardWLink.defaultProps = {
  className: "",
  link: "/#",
  desc: "",
  date: "",
  location: "",
  lowerTheTag: null,
  heading: "",
}

export default styled(EventCardWLink)``
