import styled from "styled-components"
import { InternalArrow } from "../../utils/icons"

export const ButtonItem = styled.button`
  display: flex;
  align-items: center;
  width: inherit;
  height: auto;
  color: ${(props) => props.theme.buttonColor};
  border: 0;
  font-family: ${(props) => props.themefontStyleSecondary};
  font-weight: inherit;
  font-size: 0.875rem;
  background: ${(props) => props.theme.buttonBackground};
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.buttonHover};
  }
`
export const Link = styled.a`
  width: 100%;
  height: 100%;
  color: inherit;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  background: inherit;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background: inherit;
  }
`
export const Icon = styled(InternalArrow)`
  height: 10px;
  margin-left: 1rem;
`
