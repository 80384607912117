import styled from "styled-components"
import media from "styled-media-query"

export const TextWrapper = styled.div`
  ${media.greaterThan("medium")`
    float: left;
    margin-right: 6rem;
  `}
`

export const Wrapper = styled.div`
  overflow: hidden;
`

export const Title = styled.h1`
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${(props) => props.theme.text01};
  font-family: ${(props) => props.theme.fontStyleSecondary};
  margin-top: 0rem;
`

export const Text = styled.h2`
  font-size: 2rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  line-height: 2.375rem;
  margin: 0rem;
`
