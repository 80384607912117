import styled from "styled-components"
import media from "styled-media-query"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.text01};
  font-family: ${(props) => props.theme.fontStylePrimary};
`
export const Text = styled.p`
  color: inherit;
  font-family: inherit;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  max-width: ${(props) => props.width};
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    margin-top: 1rem;
    font-size: 1.25rem;
  `}
`
