import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./contentTags.styles"

const ContentTags = ({ tags, className }) => {
  return (
    <S.Tags className={className}>
      {tags?.map((tag, i) => {
        const lastTag = tags.slice(-1)[0]
        if (tag !== lastTag) {
          return <S.Tag key={i}>{tag},</S.Tag>
        }
        return <S.Tag key={i}>{tag}</S.Tag>
      })}
    </S.Tags>
  )
}

ContentTags.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
}

ContentTags.defaultProps = {
  className: "",
  tags: "",
}

export default styled(ContentTags)``
