import { useStaticQuery, graphql } from "gatsby"

export const useSiteData = () => {
  const { contentfulWebsiteDetails } = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteDetails {
          siteMetadata {
            title
            socials {
              twitter
              facebook
              instagram
              linkedin
            }
          }
        }
      }
    `
  )
  return contentfulWebsiteDetails
}
