import styled from "styled-components"
import media from "styled-media-query"

export const Button = styled.button`
  width: 1.25rem;
  height: 1.25rem;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0rem;
`
export const Alert = styled.span`
  padding: 0.5rem 1rem;
  border-radius: 2px;
  font-family: ${(props) => props.theme.fontStyleTertiary};
  background: ${(props) => props.theme.ui05};
  color: ${(props) => props.theme.ui03};
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-right-color: ${(props) => props.theme.ui05};
    border-left: 0;
    margin-top: -0.69rem;
    margin-left: -0.56rem;
  }
  ${media.greaterThan("medium")`
  &:after {
    margin-top: -0.9rem;
    }
  }
`}
`
export const Links = styled.a`
  &:hover,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
  width: 1.25rem;
  height: 1.25rem;
`
export const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  width: max-content;
  grid-template-rows: min-content;
  grid-template-columns: repeat(4, 1fr) min-content;
  align-items: center;
  svg {
    fill: ${(props) => props.theme.icon01};
  }
  ${media.lessThan("414px")`
    align-content: center;
  `}
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: 1fr 121.95px;
    align-items: start;
    ${Alert} {
      grid-column: 2 / span 1;
    }
    ${Links}, ${Button} {
      grid-row: span 1;
      grid-column: 1;
    }
`}
`
