import styled from "styled-components"
import media from "styled-media-query"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const OutofText = styled.h1`
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${(props) => props.theme.text02};
`

export const Title = styled.h2`
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin: 0rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  ${media.greaterThan("medium")`
  /* screen width is less than 768px (medium) */
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin: 0rem;
`}
`
export const Text = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`
export const ChecklistWrapper = styled.ul`
  margin-top: 1rem;
`
export const List = styled.li`
  margin-top: 1rem;
  font-size: 1.3rem;
  list-style-type: disc;
`
