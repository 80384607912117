import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import * as S from "./videoSlide.styles"

const VideoSlide = ({ videos, className }) => {
  const videoArrays = []
  const size = 3
  const [videosList, setVideos] = useState([])
  const [first3, setFirst3] = useState([])
  const [url, setUrl] = useState({})
  const [index, setIndex] = useState(0)
  const [length, setLengthAmount] = useState(null)
  const [calls, setAmountofCalls] = useState(0)

  useEffect(() => {
    setUrl(videos[0])
    setLengthAmount(videos.length)
    while (videos.length > 0) videoArrays.push(videos.splice(0, size))
    setVideos(videoArrays)
    setFirst3(videoArrays[0])
  }, [])

  const videoTrio = videosList[index]
  const array = first3 || videoTrio
  const findCurrentIndex = videoTrio && videoTrio.indexOf(url)
  return (
    <>
      <S.Wrapper className={className}>
        <S.SlideTitle>Videos</S.SlideTitle>
        <S.VideoWrap>
          <ReactPlayer
            url={url && url.src}
            height="100%"
            width="100%"
            className="react-player"
            controls
          />
        </S.VideoWrap>
        <S.SlideWrap>
          <S.VideoTitleWrapper>
            {array &&
              array.map((video, i) => (
                <S.VideoTitle key={i} selected={url && url.src === video.src}>
                  {`${i + 1}. ${video.title}`}
                </S.VideoTitle>
              ))}
          </S.VideoTitleWrapper>
          <S.PaginationWrapper data-cy="videoButton">
            <S.LeftArrow
              onClick={() => {
                if (calls !== 0) {
                  setFirst3(null)
                  if (findCurrentIndex === 0) {
                    setIndex(index - 1)
                    const trio = videosList[index - 1]
                    setAmountofCalls(calls - 1)
                    setUrl(trio[trio.length - 1])
                  } else {
                    setAmountofCalls(calls - 1)
                    setUrl(videoTrio[findCurrentIndex - 1])
                  }
                } else {
                  const lastTrio = videosList[videosList.length - 1]
                  setUrl(lastTrio[lastTrio.length - 1])
                  setIndex(videosList.length - 1)
                  setAmountofCalls(length - 1)
                }
              }}
            />
            {array &&
              array.map((video, i) => (
                <S.VideoNumber
                  key={i}
                  selected={url.src === video.src}
                  onClick={() => setUrl(video)}
                >
                  {i + 1}
                </S.VideoNumber>
              ))}
            <S.RightArrow
              onClick={() => {
                setFirst3(null)
                if (calls !== length - 1) {
                  if (findCurrentIndex === videoTrio.length - 1) {
                    setIndex(index + 1)
                    const trio = videosList[index + 1]
                    setAmountofCalls(calls + 1)
                    setUrl(trio[0])
                  } else {
                    setAmountofCalls(calls + 1)
                    setUrl(videoTrio[findCurrentIndex + 1])
                  }
                } else {
                  const firstTrio = videosList[0]
                  setUrl(firstTrio[0])
                  setIndex(0)
                  setAmountofCalls(0)
                }
              }}
            />
          </S.PaginationWrapper>
        </S.SlideWrap>
      </S.Wrapper>
    </>
  )
}

VideoSlide.propTypes = {
  className: PropTypes.string,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      src: PropTypes.string,
    })
  ).isRequired,
}

VideoSlide.defaultProps = {
  className: "",
}

export default styled(VideoSlide)``
