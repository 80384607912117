import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./details.styles"

const Details = ({ content, className }) => {
  return (
    <S.Wrapper className={className}>
      {content.map((item, i) => {
        return (
          <S.TextWrapper key={i}>
            <S.Title>{item.label}</S.Title>
            <S.Text>{item.text}</S.Text>
          </S.TextWrapper>
        )
      })}
    </S.Wrapper>
  )
}

Details.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
}

Details.defaultProps = {
  className: "",
}

export default styled(Details)``
