import { useStaticQuery, graphql } from "gatsby"

export const useDefaultImage = () => {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "defaultImage.png" }) {
          childImageSharp {
            fluid(maxWidth: 150, quality: 100) {
              src
            }
          }
        }
      }
    `
  )
  return file.childImageSharp
}
