import styled from "styled-components"
import media from "styled-media-query"

export const Tags = styled.ul`
  display: grid;
  max-width: 100%;
  grid-template-columns: min-content min-content min-content;
  grid-template-rows; min-content;
  grid-gap: 5px;
  grid-auto-flow: row dense;
  list-style: none;
`
export const Tag = styled.li`
  font-size: 12px;
  line-height: 15px;
  grid-column: span 1;
  width: min-content;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    line-height: 18px;
  `}
`
