import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./articleImages.styles"

const ArticalImages = ({ className, images, oneImageOnly }) => {
  return (
    <>
      <S.Wrapper
        className={`article_images ${className}`}
        oneImageOnly={oneImageOnly || images?.length === 1}
        images={images}
      >
        {images?.length > 0 && (
          <>
            <S.LargeImage>
              <S.Image
                images={
                  images && images.length >= 1 ? images[0].fields : images
                }
              />
              <S.Caption>
                {images[0] && images[0]?.fields?.description?.["en-US"]}
              </S.Caption>
            </S.LargeImage>
            {images[1] && (
              <S.SmallImage>
                <S.Image images={images[1].fields} />
                <S.Caption>{images[1].fields.description?.["en-US"]}</S.Caption>
              </S.SmallImage>
            )}
          </>
        )}
      </S.Wrapper>
    </>
  )
}

ArticalImages.propTypes = {
  className: PropTypes.string,
  oneImageOnly: PropTypes.bool,
  images: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}

ArticalImages.defaultProps = {
  className: "",
  oneImageOnly: false,
}

export default styled(ArticalImages)``
