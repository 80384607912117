import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import * as S from "./articleCard.styles"
import { useDefaultImage } from "../../queries/defaultImageGraphql"

const ArticleCard = ({
  img,
  title,
  desc,
  author,
  className,
  link,
  landscape,
}) => {
  const externalLink = link.includes(`${`https` || `www`}`)
  const { fluid } = useDefaultImage()

  return (
    <S.Container className={className}>
      <S.Wrapper href={link} landscape={landscape} data-cy="ArticleLinks">
        <S.ImageContainer>
          <Img
            fluid={img?.fluid || fluid}
            alt={img?.description || "This is a default Image"}
            objectFit="cover"
          />
        </S.ImageContainer>
        <S.Title>{title}</S.Title>
        {desc && <S.Desc>{`${desc.substring(0, 250)}...`}</S.Desc>}
        <S.Author>{author}</S.Author>
        {externalLink ? <S.ExternalIcon /> : <S.InternalIcon />}
      </S.Wrapper>
    </S.Container>
  )
}

ArticleCard.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  img: PropTypes.shape({}),
  desc: PropTypes.string,
  landscape: PropTypes.bool,
}

ArticleCard.defaultProps = {
  className: "",
  img: {
    fluid:
      "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png",
    description: "This is a placeholder image",
  },
  desc: "",
  landscape: false,
}

export default styled(ArticleCard)``
