import { useStaticQuery, graphql } from "gatsby"

export const useSeoData = () => {
  const { contentfulWebsiteDetails } = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteDetails {
          homePageTextureImage {
            fluid(maxWidth: 1312, quality: 100) {
              src
            }
          }
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  return contentfulWebsiteDetails
}
