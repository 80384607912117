import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import * as S from "./methodCard.styles"
import { useDefaultImage } from "../../queries/defaultImageGraphql"

const MethodCard = ({ img, title, tags, className, link }) => {
  const { fluid } = useDefaultImage()
  console.log("fluid", fluid)
  console.log("img?.fluid", img?.fluid)
  return (
    <S.Wrapper className={className} to={link} data-cy="methodLinks">
      <Img
        fluid={img?.fluid || fluid}
        alt={img?.description || "This is a default image"}
        objectFit="cover"
      />
      <S.Title>{title}</S.Title>
      <S.Tags tags={tags} />
    </S.Wrapper>
  )
}

MethodCard.propTypes = {
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  img: PropTypes.shape({}).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}

MethodCard.defaultProps = {
  className: "",
  tags: [],
}

export default styled(MethodCard)``
