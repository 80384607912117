import styled from "styled-components"
import media from "styled-media-query"

export const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: inherit;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
      height: inherit;
      > * {
        max-width: 45.45%;
      }
  `}
`
export const SubHeading = styled.h1`
  font-family: ${(props) => props.theme.fontStyleQuaternary};
  color: ${(props) => props.theme.text02};
  margin-top: 0px;
  font-size: 0.75rem;
  line-height: 0.9rem;
  text-align: inherit;
  align-self: center;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    margin-bottom: 1rem;
  `}
`
export const Heading = styled.h2`
  font-family: ${(props) => props.theme.fontStylePrimary};
  margin-top: 0px;
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: inherit;
  align-self: center;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    margin-bottom: 1rem;
  `}
`
export const Description = styled.p`
  font-family: ${(props) => props.theme.fontStyleSecondary};
  font-size: 1rem;
  text-align: inherit;
  align-self: center;
  line-height: 1.25rem;
`
