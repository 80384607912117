import React from "react"
import styled from "styled-components"
import FileSaver from "file-saver"
import filesize from "filesize"
import PropTypes from "prop-types"
import * as S from "./download.styles"

const Download = ({ fileName, label, className, url, fileSize }) => {
  return (
    <S.ButtonItem className={className}>
      <S.Link onClick={() => FileSaver.saveAs(url, fileName)}>
        {`${label} (${filesize(fileSize, { exponent: 2 })})`}
        <S.Icon />
      </S.Link>
    </S.ButtonItem>
  )
}

Download.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
}

Download.defaultProps = {
  className: "",
}

export default styled(Download)``
