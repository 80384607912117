import styled from "styled-components"
import media from "styled-media-query"
import { ExternalArrow, InternalArrow } from "../../utils/icons"

// medium is 768px
// 1055px is the minmium container width on designs

export const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`
export const Wrapper = styled.a`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  color: inherit;
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props.theme.hoverPrimary};
  }
  position: relative;
  height: inherit;
`
export const Tag = styled.h1`
  margin: 0rem;
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 2rem;
  grid-column: span 2;
`
export const Image = styled.img.attrs((props) => ({
  src: props.image.resize.src,
  alt: props.image.description,
}))`
  width: 100%;
  object-fit: cover;
  margin-bottom: 2rem;
  grid-column: span 1;
`
export const Title = styled.h2`
  grid-column: span 2;
  font-size: 2rem;
  line-height: 2.375rem;
  margin-top: 0rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
`

export const Description = styled.p`
  grid-column: span 2;
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 1rem 0rem;
  white-space: pre-wrap;
  ${media.greaterThan("medium")`
      padding-bottom: 0rem;
  `}
`
export const Cta = styled.h2`
  grid-column: 1 / span 1;
  align-self: end;
  font-size: 0.75rem;
  line-height: 0.9rem;
  font-family: ${(props) => props.theme.fontStyleTertiary};
  padding-top: 1rem;
`

export const InternalIcon = styled(InternalArrow)`
  grid-column: 2 / span 1;
  justify-self: end;
  align-self: end;
  width: 25px;
  height: 25px;
  ${media.greaterThan("medium")`
    width: 2.439vw;
    height: auto;
  `}
`
export const ExternalIcon = styled(ExternalArrow)`
  grid-column: 2 / span 1;
  justify-self: end;
  width: 25px;
  height: 25px;
  align-self: end;
  ${media.greaterThan("medium")`
    width: 2.439vw;
    height: auto;
  `}
`
