import styled from "styled-components"
import { Download } from "../../utils/icons"

export const ButtonItem = styled.button`
  width: max-content;
  color: ${(props) => props.theme.text03};
  border: 0;
  background: none;
  cursor: pointer;
  font-family: ${(props) => props.themefontStyleSecondary};
  font-size: 0.75rem;
  line-height: 0.9rem;
  font-weight: bold;
  padding: 0rem;
`
export const Link = styled.a`
  color: inherit;
  border: 0;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.buttonHover};
  }
`
export const Icon = styled(Download)`
  fill: ${(props) => props.theme.ui06};
  height: 15px;
  margin-left: 0.5rem;
`
