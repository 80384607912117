import { useStaticQuery, graphql } from "gatsby"

export const allChallenges = () => {
  const { allContentfulChallenge } = useStaticQuery(
    graphql`
      query ChallengesQuery {
        allContentfulChallenge(sort: { fields: [createdAt], order: DESC }) {
          nodes {
            slug
            themeName
            year
          }
        }
      }
    `
  )
  return allContentfulChallenge
}
