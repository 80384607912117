import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Checkbox } from "carbon-components-react"
import * as S from "./methodContent.styles"
import ReactPlayer from "react-player"

import { FormatRichText } from ".."

const MethodContent = ({
  index,
  total,
  title,
  text,
  checklist,
  className,
  video,
}) => {
  return (
    <S.Wrapper className={className}>
      <S.OutofText>{`${index}/${total}`}</S.OutofText>
      <S.Title>{title}</S.Title>
      <S.Text>
        <FormatRichText width="608px" text={text} />
      </S.Text>
      {video && video !== "-" && (
        <ReactPlayer
          url={video}
          height={400}
          width="auto"
          className="react-player"
          controls
        />
      )}
      {checklist && (
        <S.ChecklistWrapper>
          {checklist.map((checkbox, i) => (
            <S.List key={i} id={`${i}`}>
              {checkbox}
            </S.List>
          ))}
        </S.ChecklistWrapper>
      )}
    </S.Wrapper>
  )
}

MethodContent.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  total: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  checklist: PropTypes.arrayOf(PropTypes.string),
  video: PropTypes.string,
}

MethodContent.defaultProps = {
  className: "",
  index: "",
  total: "",
  checklist: [],
  video: "",
}

export default styled(MethodContent)``
