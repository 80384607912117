import styled, { css } from "styled-components"
import media from "styled-media-query"

export const LargeImage = styled.figure`
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-column: 1 / 2;
  `}
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  gap: 1.5rem;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    gap: 2rem;
    ${(props) =>
      props.oneImageOnly &&
      css`
        ${LargeImage} {
          grid-column: 1 / span 2;
          margin-right: 2rem;
        }
      `}
  `}
`

export const SmallImage = styled.figure`
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-column: 2 / 3;
    grid-row: 1/ 2;
  `}
`
export const Caption = styled.figcaption`
  margin-top: 0.625rem;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: #525252;
  font-family: ${(props) => props.theme.fontStyleTertiary};
`
export const Image = styled.img.attrs((props) => ({
  src: props?.images?.file?.["en-US"]?.url,
  alt: props?.images?.description?.["en-US"],
}))`
  width: 100%;
  object-fit: cover;
`
