import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import * as S from "./banner.styles"
import { useDefaultImage } from "../../queries/defaultImageGraphql"

const Banner = ({ className, image }) => {
  const { fluid } = useDefaultImage()

  return (
    <S.BannerWrapper className={className}>
      <Img
        fluid={image?.fluid || fluid}
        alt={image?.description || "This is a default image"}
        objectFit="cover"
      />
    </S.BannerWrapper>
  )
}

Banner.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
}

Banner.defaultProps = {
  className: "",
  image: {
    fluid: {
      src:
        "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png",
    },
    description: "This is a default image",
  },
}

export default styled(Banner)``
