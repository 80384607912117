import styled, { css } from "styled-components"
import media from "styled-media-query"
import { ExternalArrow, InternalArrow } from "../../utils/icons"

// medium is 768px
// 1055px is the minmium container width on designs

export const InternalIcon = styled(InternalArrow)`
  align-self: end;
  justify-self: end;
  width: 25px;
  height: 25px;
  grid-row: end;
  grid-column: 2 / span 1;
  ${media.greaterThan("414px")`
   width: 2.439vw;
   height: auto;
   grid-row: -1;
   grid-column: 4 / 5;
`}
`
export const ExternalIcon = styled(ExternalArrow)`
  align-self: end;
  justify-self: end;
  width: 25px;
  height: 25px;
  grid-row: end;
  grid-column: 2 / span 1;
  ${media.greaterThan("414px")`
     width: 2.439vw;
     height: auto;
     grid-row: -1;
     grid-column: 4 / 5;
 `}
`
export const Author = styled.h2`
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${(props) => props.theme.text02};
  font-family: ${(props) => props.theme.fontStyleTertiary};
  align-self: end;
  grid-row: end;
  grid-column: 1 / span 1;
  margin-bottom: 0.5rem;
  padding-top: 1rem;
  ${media.greaterThan("414px")`
     grid-column: 3 / span 1;
     grid-row: -1;
 `}
`

export const Title = styled.h1`
  margin-top: 0px;
  font-size: 1.375rem;
  line-height: 1.6rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  ${media.greaterThan("414px")`
  margin-bottom: 0rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
`}
`
export const Desc = styled.p`
  margin-top: 0px;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: ${(props) => props.theme.fontStyleSecondary};
`
export const ImageContainer = styled.div`
  grid-row: 1 / span 1;
  grid-column: span 2;
  position: relative;
  height: 100%;
  padding-top: 56.25%;
  div,
  img {
    object-fit: cover;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${media.greaterThan("414px")`
    grid-row: 1 / end;
    grid-column: 1 / span 2;
`}
`
export const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`

export const Wrapper = styled.a`
  position: relative;
  display: grid;
  grid-template-rows: 2fr auto;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1rem;
  height: inherit;
  color: inherit;
  text-decoration: none;
  ${Title}, ${Desc} {
    grid-row: span 1;
    grid-column: span 2;
  }
  &:hover {
    background-color: ${(props) => props.theme.hoverPrimary};
  }
  height: inherit;

  ${media.greaterThan("414px")`
   grid-template-columns: repeat(4, 1fr);
   grid-template-rows: auto auto auto;
   grid-column-gap: 1rem;
   grid-row-gap: 0rem;
   ${Title}, ${Desc}  {
     grid-column: 3 / span 2;
   }
  ${(props) =>
    props.landscape &&
    css`
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 0rem;
      grid-row-gap: 1rem;
      ${ImageContainer} {
        height: 0;
        grid-row: span 1;
        grid-column: 1 / end;
        width: 100%;
      }
      ${Title} {
        grid-row: 2 / 3;
        grid-column: 1 / end;
        height: min-content;
        align-self: end;
      }
      ${Desc} {
        grid-row: 3 / span 1;
        grid-column: 1 / end;
      }
      ${Author} {
        grid-row: 4 / span 1;
        grid-column: 1 / 3;
        align-self: end;
      }
      svg {
        grid-row: 4 / span 1;
        grid-column: -1;
      }
    `}
 `}
`
