import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as clipboard from "clipboard-polyfill"
import { Facebook, Twitter, LinkedIn, Share } from "../../utils/icons"
import * as S from "./socials.styles"

const Socials = ({ title, hashtags, description, className }) => {
  const url = typeof window !== "undefined" && window.location.href
  const [alert, setAlert] = useState(false)
  const titleFormated = encodeURIComponent(title)
  const hashtagsFormated = encodeURIComponent(hashtags)
  const descriptionFormated = encodeURIComponent(description)

  return (
    <S.Wrapper className={className} data-cy="socialLinks">
      <S.Links
        href={`https://www.facebook.com/sharer.php?u=${url}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Facebook alt="Share page on Facebook" />
      </S.Links>
      <S.Links
        href={`https://twitter.com/intent/tweet?url=${url}&text=${titleFormated}${
          hashtagsFormated && `&hashtags=${hashtagsFormated}`
        }
        `}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Twitter alt="Share page on Twitter" />
      </S.Links>
      <S.Links
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${titleFormated}&summary=${descriptionFormated}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <LinkedIn alt="Share page on LinkedIn" />
      </S.Links>
      <S.Button
        data-testid="Share"
        onClick={() => {
          clipboard.writeText(url)
          setAlert(!alert)
        }}
      >
        <Share alt="Copy page link" />
      </S.Button>
      {alert && <S.Alert>Link copied to clipboard</S.Alert>}
    </S.Wrapper>
  )
}

Socials.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  hashtags: PropTypes.string,
}

Socials.defaultProps = {
  className: "",
  hashtags: "",
  description: "",
}

export default styled(Socials)``
