import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./navigationCard.styles"

const NavigationCard = ({
  link,
  title,
  desc,
  cta,
  className,
  image,
  heading,
}) => {
  const externalLink = link && link.includes(`${`https` || `www`}`)
  return (
    <S.Container className={className}>
      <S.Wrapper href={link} data-cy="homePageLinks">
        {heading && <S.Tag>{heading}</S.Tag>}
        {(title && <S.Title>{title}</S.Title>) ||
          (image && <S.Image image={image} />)}
        {desc && <S.Description>{desc}</S.Description>}
        {cta && <S.Cta>{cta}</S.Cta>}
        {externalLink ? <S.ExternalIcon /> : link && <S.InternalIcon />}
      </S.Wrapper>
    </S.Container>
  )
}

NavigationCard.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  heading: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  cta: PropTypes.string,
  image: PropTypes.shape({}),
}

NavigationCard.defaultProps = {
  className: "",
  heading: "",
  cta: "",
  link: "",
  title: "",
  desc: "",
  image: {
    file: {
      url:
        "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png",
    },
    description: "This is an image",
  },
}

export default styled(NavigationCard)``
