import styled from "styled-components"

export const BannerWrapper = styled.div`
  div {
    width: 100%;
    height: inherit;
    object-fit: cover;
    display: flex;
    align-self: stretch;
  }
`
