import styled from "styled-components"
import media from "styled-media-query"
import { Button } from ".."

// medium is 768px
// 1055px is the minmium container width on designs

export const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  ${media.greaterThan("1056px")`
    padding-top: 100%;
  `}
`
export const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${(props) => props.theme.text05};
  background-color: ${(props) => props.theme.ui02};
  ${Button} {
    width: 125px;
    padding: 1rem;
    color: ${(props) => props.theme.buttonSecondaryColor} !important;
    background: ${(props) => props.theme.buttonSecondaryBackground} !important;
    &:hover {
      background: ${(props) => props.theme.buttonSecondaryHover} !important;
    }
  }
  height: inherit;
  ${media.greaterThan("1055px")`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
  `}
`
export const SubTitle = styled.h1`
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 1rem;
  align-self: center;
  font-family: ${(props) => props.theme.fontStyleTertiary};
`

export const Title = styled.h2`
  font-size: 3.375rem;
  line-height: 3.625rem;
  margin: 0rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  align-self: center;
  ${media.greaterThan("medium")`
    margin: 0rem;
`}
`

export const Desc = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 1.5rem 0rem 2rem 0rem;
  text-align: inherit;
  font-family: ${(props) => props.theme.fontStyleSecondary};
  ${media.greaterThan("medium")`
    margin-top: 1rem;
  `}
`
