import styled, { createGlobalStyle } from "styled-components"
import media from "styled-media-query"
import { Header, Footer } from ".."

export const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'theano_didotregular';
      src: url('../utils/font/theanodidot-regular-webfont.woff') format('woff');
      font-weight: normal;
      font-style: normal;

  }
  body {
    margin: 0;
  }
  .background {
    background-color: #F2F5F6;
  }
`
export const Container = styled.div`
  main {
    margin: 0 auto;
    max-width: 1312px;
    min-height: 100vh;
  }
  ${Header} {
    height: 66px;
    border-bottom: ${(props) => props.theme.borderStyle};
    div {
      padding-left: 1rem;
      max-width: 1312px;
      margin: 0 auto;
    }
    ul {
      border-left: ${(props) => props.theme.borderStyle};
    }
    li {
      border-bottom: ${(props) => props.theme.borderStyle};
    }
    svg {
      padding-right: 1rem;
    }
  }
  ${Footer} {
    div {
      max-width: 1312px;
      padding: 1rem 1rem 1.5rem 1rem;
      margin: 0 auto;
    }
  }
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
      main {
        position: relative;
        &:after, :before {
          content: '';
          position: absolute;
          height: 100%;
          top: 0;
          z-index: 10;
        }
        &:after {
          right: 0;
          border-right: ${(props) => props.theme.borderStyle};
        }
        &:before {
          border-left: ${(props) => props.theme.borderStyle};
        }
      }
      ${Header} {
        div {
          padding: 0rem 2rem;
        }
        ul, li {
          border: none;
        }
      }
      ${Footer} {
        div  {
          padding: 1.5rem 2rem;
        }
      }

  `}
`
