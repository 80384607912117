import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { format } from "date-fns"
import * as S from "./eventCard.styles"

const EventCard = ({
  title,
  desc,
  date,
  location,
  className,
  lowerTheTag,
  heading,
}) => {
  const formatedDate = date && format(new Date(date), "dd.MM.yyyy")
  const datePresented = location
    ? `${location} | ${formatedDate}`
    : formatedDate
  return (
    <S.Container className={className}>
      <S.Wrapper>
        <S.Tag
          lowerTheDate={lowerTheTag}
          aria-label={
            (heading && `Heading`) || location
              ? `Date and Event Location`
              : `Date`
          }
        >
          {heading || datePresented}
        </S.Tag>
        <S.Title>{title}</S.Title>
        {desc && (
          <S.Description>{`${desc.substring(0, 300)}...`}</S.Description>
        )}
      </S.Wrapper>
    </S.Container>
  )
}

EventCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  date: PropTypes.string,
  location: PropTypes.string,
  lowerTheTag: PropTypes.bool,
  heading: PropTypes.string,
}

EventCard.defaultProps = {
  className: "",
  desc: "",
  date: "",
  location: "",
  lowerTheTag: null,
  heading: "",
}

export default styled(EventCard)``
