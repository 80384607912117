import React from "react"
import { ThemeProvider } from "styled-components"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Header, Footer, SEO } from ".."
import theme from "../../utils/theme"
import ContenfulLogo from "../../utils/images/contentful.png"
import { allChallenges } from "../../queries/challengeGraphql"
import { useSiteData } from "../../queries/siteGraphql"
import * as S from "./layout.styles"

const Layout = ({ children, title, image, description }) => {
  const { nodes } = allChallenges()
  const challengeURL = nodes
    ? `challenge-${nodes[0]?.year}/${nodes[0]?.slug}`
    : `/challenge`
  const links = [
    {
      url: "/about-us",
      name: "About Us",
    },
    {
      url: "/voices",
      name: "Voices",
    },
    {
      url: "/methods",
      name: "Methods",
    },
    {
      url: "/events",
      name: "Events",
    },
    {
      url: `${challengeURL}`,
      name: "Challenge",
    },
  ]

  const { siteMetadata } = useSiteData()

  return (
    <ThemeProvider theme={{ ...theme }}>
      <S.Container>
        <S.GlobalStyle />
        <Helmet bodyAttributes={{ class: `background` }}>
          <link
            rel="stylesheet"
            href="https://unpkg.com/carbon-components@10.9.1/css/carbon-components.min.css"
          />
        </Helmet>
        <SEO title={title} description={description} image={image} />
        <Header links={links} siteTitle={siteMetadata.title} />
        <main>{children}</main>
        <Footer
          links={links}
          socials={siteMetadata.socials}
          image={ContenfulLogo}
        />
      </S.Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
}
Layout.defaultProps = {
  title: "",
  image: "",
  description: "",
}

export default Layout
