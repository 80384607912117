import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"

export const Wrapper = styled.footer`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.ui05};
  color: ${(props) => props.theme.text04};
`
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  grid-gap: 2rem;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
      grid-template-rows: auto auto;
  `}
`
export const PagNav = styled.nav`
  grid-column: 1 / span 1;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
      grid-column: 1 / span 1;
      width: max-content;
  `}
`
export const NavWrapper = styled.ul`
  width: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  white-space: nowrap;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
      grid-column: 1 / span 1;
      width: max-content;
      display: flex;
      justify-content: space-between;
  `}
`
export const Item = styled.li`
  font-size: 14px;
  line-height: 18px;
  margin-right: 1.5rem;
`
export const LinkTag = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: ${(props) => props.theme.text03};
  }
`

export const Href = styled.a`
  width: auto;
`
export const SocialLinks = styled.ul`
  width: 150px;
  grid-row: 2 / span 1;
  svg {
    fill: ${(props) => props.theme.ui03};
    height: 18px;
  }
  display: flex;
  justify-content: space-between;
  ${media.greaterThan("medium")`
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
  `}
`
export const Text = styled.p`
  line-height: revert;
`
export const Sponsor = styled.span`
  grid-row: 3 / span 1;
  display: flex;
  align-content: center;
  width: max-content;
  height: 19px;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
    justify-self: flex-end;
  `}
`

export const Logo = styled.img.attrs((props) => ({ src: props.image }))`
  width: 92px;
  height: inherit;
  display: inline-block;
  margin-left: 0.75rem;
`
