import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./textButton.styles"

const TextButton = ({ link, label, className }) => {
  return (
    <S.ButtonItem className={className}>
      <S.Link href={link}>
        {label}
        <S.Icon />
      </S.Link>
    </S.ButtonItem>
  )
}

TextButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

TextButton.defaultProps = {
  className: "",
}

export default styled(TextButton)``
