import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./footer.styles"
import { Facebook, Twitter, LinkedIn, Instagram } from "../../utils/icons"

const Footer = ({ links, className, socials, image }) => {
  const { twitter, instagram, linkedin, facebook } = socials
  return (
    <S.Wrapper className={className} data-cy="cy-footer">
      <S.Container>
        <S.PagNav>
          <S.NavWrapper>
            {links.map((link, i) => {
              return (
                <S.Item key={i}>
                  <S.LinkTag to={link.url}>{link.name}</S.LinkTag>
                </S.Item>
              )
            })}
          </S.NavWrapper>
        </S.PagNav>
        <S.SocialLinks data-cy="socialLinks">
          {twitter && (
            <S.Href
              href={twitter}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit our Twitter"
            >
              <Twitter alt="Visit our Twiiter" />
            </S.Href>
          )}
          {instagram && (
            <S.Href
              href={instagram}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit our Instagram"
            >
              <Instagram alt="Visit our Instagram" />
            </S.Href>
          )}
          {linkedin && (
            <S.Href
              href={linkedin}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit our LinkedIn"
            >
              <LinkedIn alt="Visit our LinkedIn" />
            </S.Href>
          )}
          {facebook && (
            <S.Href
              href={facebook}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Visit our Facebook"
            >
              <Facebook alt="Visit our Facebook" />
            </S.Href>
          )}
        </S.SocialLinks>
        <S.Sponsor>
          <S.Text>Supported by</S.Text>
          <S.Logo image={image} alt="Contentful Logo" />
        </S.Sponsor>
      </S.Container>
    </S.Wrapper>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  socials: PropTypes.shape({
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
    instagram: PropTypes.string,
  }),
  image: PropTypes.string.isRequired,
}

Footer.defaultProps = {
  className: "",
  socials: {},
}

export default styled(Footer)``
