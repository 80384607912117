import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import * as S from "./speakerCard.styles"
import { InternalArrow } from "../../utils/icons"

const SpeakerCard = ({ img, name, occupation, desc, className, link }) => {
  return (
    <S.Wrapper className={className}>
      <S.ImageContainer>
        <S.Image>
          <Img
            fluid={img?.fluid}
            alt={img?.description || "This is a default image"}
            objectFit="cover"
          />
        </S.Image>
      </S.ImageContainer>
      <S.Content>
        <S.Name>{name}</S.Name>
        {occupation && <S.Occupation>{occupation}</S.Occupation>}
        {desc && <S.Desc>{desc}</S.Desc>}
        {link && (
          <S.Link href={link}>
            Read more
            <InternalArrow />
          </S.Link>
        )}
      </S.Content>
    </S.Wrapper>
  )
}

SpeakerCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  occupation: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  img: PropTypes.shape({}).isRequired,
}

SpeakerCard.defaultProps = {
  className: "",
}

export default styled(SpeakerCard)``
