import styled from "styled-components"
import media from "styled-media-query"

export const Name = styled.h1`
  font-size: 2rem;
  line-height: 2.375rem;
  margin: 0rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
`
export const Link = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text03};
  &:hover {
    color: ${(props) => props.theme.hoverTertiary};
    background: none;
  }
  svg {
    width: 0.9rem;
    margin-left: 0.5rem;
    > * {
      fill: currentColor;
     }
   }
 }
`
export const Occupation = styled.h2`
  font-size: 0.75rem;
  line-height: 0.9rem;
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    margin: 1rem 0rem;
  `}
`
export const Desc = styled.p`
  margin: 0rem;
  font-size: 1rem;
  line-height: 1.375rem;
`

export const Image = styled.div`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 400px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  ${media.greaterThan("medium")`
     grid-row: 1 / end;
     grid-column: 2/ end;
  `}
`
export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${media.lessThan("medium")`
    padding-top: 56.25%;
`}
  ${media.greaterThan("medium")`
     grid-row: 1 / end;
     grid-column: 2/ end;
     height: 100%;
     width: auto;
     min-height: 202px;
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.greaterThan("medium")`
     margin-right: 9%;
     grid-row: 1 / end;
  `}
  ${media.lessThan("medium")`
    margin-top: 20px;
`}
`
export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  ${media.greaterThan("medium")`
    grid-template-columns: 2fr 1fr;
    grid-template-rows: none;
  `}
`
