import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./headingCard.styles"

const HeadingCard = ({ heading, description, subHeading, className }) => {
  return (
    <S.Wrapper className={className}>
      {subHeading && <S.SubHeading>{subHeading}</S.SubHeading>}
      <S.Heading data-cy="pageHeaderTitle">{heading}</S.Heading>
      <S.Description>{description}</S.Description>
    </S.Wrapper>
  )
}

HeadingCard.propTypes = {
  className: PropTypes.string,
  subHeading: PropTypes.string,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

HeadingCard.defaultProps = {
  className: "",
  subHeading: "",
}

export default styled(HeadingCard)``
