import React from "react"
import { navigate } from "@reach/router"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as S from "./templateHeading.styles"

const TemplateHeading = ({
  heading,
  title,
  desc,
  className,
  parentContext,
}) => {
  return (
    <S.Wrapper className={className}>
      {heading ? (
        <S.Heading>{heading}</S.Heading>
      ) : (
        <S.Heading>
          <S.BreadcrumbWrapper>
            <S.Breadcrumb
              to="#"
              onClick={() => {
                navigate(-1)
                return false
              }}
            >
              {parentContext.parentLabel}
            </S.Breadcrumb>
            <S.Breadcrumb to={parentContext.location.pathname}>
              {parentContext.childLabel}
            </S.Breadcrumb>
          </S.BreadcrumbWrapper>
        </S.Heading>
      )}
      <S.Title>{title}</S.Title>
      {desc && <S.Desc>{desc}</S.Desc>}
    </S.Wrapper>
  )
}

TemplateHeading.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  parentContext: PropTypes.objectOf(PropTypes.string),
}

TemplateHeading.defaultProps = {
  className: "",
  heading: "",
  desc: "",
  parentContext: {},
}

export default styled(TemplateHeading)``
