import styled from "styled-components"
import { Link } from "gatsby"
import { ContentTags } from ".."

export const Title = styled.h1`
  align-items: start;
  margin-top: 0rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding-top: 1rem;
  padding-left: 0.75rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  grid-row: 2 / span 1;
`
export const Tags = styled(ContentTags)`
  align-items: end;
  padding: 1rem 0rem;
  padding-left: 0.75rem;
  grid-row: 3 / span 1;
`
export const Wrapper = styled(Link)`
  display: grid;
  grid-template-rows: 1.25fr 1fr auto;
  background: ${(props) => props.theme.ui03};
  color: inherit;
  height: auto;
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props.theme.hoverPrimary};
  }
  ${Title}, ${Tags} {
    max-width: 82.5%;
  }
  div {
    width: 100%;
    object-fit: cover;
    display: flex;
    align-self: stretch;
    grid-row: 1 / span 1;
    max-height: 200px;
  }
`
