import styled, { css } from "styled-components"
import media from "styled-media-query"
import { InternalArrow } from "../../utils/icons"

// medium is 768px

export const Title = styled.h2`
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  grid-column: span 2;
  font-family: ${(props) => props.theme.fontStylePrimary};
`

export const Tag = styled.h1`
  margin: 0rem;
  font-size: 0.75rem;
  line-height: 0.9rem;
  grid-column: 1 / span 1;
  margin-bottom: 1rem;
  font-family: ${(props) => props.theme.fontStyleTertiary};
  ${(props) =>
    props.lowerTheDate &&
    css`
      grid-row: end;
      align-self: center;
      margin: 0rem;
    `}
`
export const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  ${media.greaterThan("1056px")`
    padding-top: 100%;
  `}
`

export const Wrapper = styled.a`
  position: relative;
  display: grid;
  grid-template-rows: min-content min-content min-content 1fr;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  color: inherit;
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props.theme.hoverPrimary};
  }
  height: inherit;
  ${media.greaterThan("1055px")`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
  `}
`

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  grid-column: span 2;
  padding-top: 1.5rem;
  ${media.greaterThan("medium")`
      padding-top: 2rem;
  `}
`
export const InternalIcon = styled(InternalArrow)`
  align-self: center;
  justify-self: end;
  width: 25px;
  height: 25px;
  grid-column: 2 / span 1;
  grid-row: end;
  margin-top: 20px;
  ${media.greaterThan("medium")`
      width: 2.439vw;
      height: auto;
  `}
`
