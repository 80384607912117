import React from "react"
import styled from "styled-components"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// import { Checkbox } from 'carbon-components-react'
import PropTypes from "prop-types"
import * as S from "./formatRichText.styles"

const options = {
  renderMark: {
    [MARKS.CODE]: (text) => <S.Highlight>{text}</S.Highlight>,
    [MARKS.BOLD]: (text) => <b>{text}</b>,
    [MARKS.ITALIC]: (text) => <i>{text}</i>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <S.H1>{children}</S.H1>,
    [BLOCKS.HEADING_2]: (node, children) => <S.H2>{children}</S.H2>,
    [BLOCKS.HEADING_3]: (node, children) => <S.H3>{children}</S.H3>,
    [BLOCKS.HEADING_4]: (node, children) => <S.H4>{children}</S.H4>,
    [BLOCKS.HEADING_5]: (node, children) => <S.H5>{children}</S.H5>,
    [BLOCKS.HEADING_6]: (node, children) => <S.H6>{children}</S.H6>,
    [BLOCKS.PARAGRAPH]: (node, children) => <S.P>{children}</S.P>,
    // [BLOCKS.LIST_ITEM]: (node, children) => (
    //   <Checkbox>{children}
    // ),
    [BLOCKS.UL_LIST]: (node, children) => <S.UL>{children}</S.UL>,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const img = node?.data?.target
      return <S.Images images={[img]} />
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const img = node.data.target
      return <S.Images images={[img]} oneImageOnly />
    },
  },
}

const FormatRichText = ({ text, className }) => {
  return (
    <S.Wrapper className={className}>
      {documentToReactComponents(text, options)}
    </S.Wrapper>
  )
}

FormatRichText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.shape({}).isRequired,
}

FormatRichText.defaultProps = {
  className: "",
}

export default styled(FormatRichText)``
