import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"

export const Breadcrumb = styled(Link)``
export const BreadcrumbWrapper = styled.div``

export const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
`
export const Heading = styled.h1`
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${(props) => props.theme.text02};
  font-family: ${(props) => props.theme.fontStyleTertiary};
  ${BreadcrumbWrapper}, ${Breadcrumb} {
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: inherit;
  }
  ${Breadcrumb} {
    &:hover {
      color: ${(props) => props.theme.hoverTertiary};
    }
    &:not(:last-of-type):after {
      content: "/";
      padding: 0rem 0.3rem;
    }
  }

  .breadcrumb,
  .breadcrumb__link {
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: inherit;
    .breadcrumb__link {
      padding-left: 0.3rem;
    }
  }
`
export const Title = styled.h2`
  font-size: 3rem;
  line-height: 3.625rem;
  margin: 0rem;
  font-family: ${(props) => props.theme.fontStylePrimary};
  ${media.greaterThan("medium")`
  /* screen width is less than 768px (medium) */
    font-size: 3.5rem;
    line-height: 3rem;
    margin: 0rem;
`}
`

export const Desc = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  margin-top: 1.5rem;
  font-family: ${(props) => props.theme.fontStyleSecondary};
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    margin-top: 1rem;
  `}
`
